import { useContext, useEffect, useState } from "react";
import { useImpersonationService } from "../../logic/auth/impersonation.service";
import { UserContext } from "../../logic/auth/user-context";
import { UserDto } from "@arrowsup/platform-dtos";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Loading } from "../feedback/loading";
import { useNavigate } from "react-router-dom";

export const UserPicker: React.FC = () => {
  const impersonationService = useImpersonationService();
  const userContext = useContext(UserContext);
  const [users, setUsers] = useState<UserDto[]>([userContext.user]);
  const org = userContext.impersonatedOrg ?? userContext.user.org;
  const navigate = useNavigate();

  useEffect(() => {
    impersonationService
      .getUsers(org)
      .then((users) => {
        if (!users.some((user) => user.id === userContext.user.id)) {
          setUsers([userContext.user, ...users]);
        } else {
          setUsers(users);
        }
      })
      .catch(console.error);
  }, [org, userContext.user, impersonationService]);

  const idPrefix = "impersonate-user-";
  const inputLabelId = idPrefix + "label";

  const selectedUserId =
    userContext.impersonatedUser?.id ?? userContext.user.id;

  if (users.find((_) => _.id === selectedUserId) === undefined) {
    // Assume that the component was rerendered and we're reloading
    // possible users.
    return <Loading />;
  }

  return (
    <FormControl size="small" fullWidth={true} disabled={users.length === 0}>
      <InputLabel id={inputLabelId} color="secondary">
        User
      </InputLabel>
      <Select
        labelId={inputLabelId}
        id={idPrefix + "select"}
        value={selectedUserId}
        label="User"
        onChange={(e) => {
          const user = users.find((user) => user.id === e.target.value);
          if (!user) {
            throw new Error("User not found");
          }
          userContext.setImpersonatedUser(user);
          navigate("/");
        }}
      >
        {users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
