import { OauthProvider } from "@arrowsup/platform-dtos";
import { OauthService } from "../../../logic/integrations/oauth/oauth.service";
import { useContext } from "react";
import { ApiContext } from "../../../logic/api/api-context";

/**
 * Create the various oauth services
 * @param api the api used by the oauth services to communicate with platform
 * @returns a record of OauthProvider to OauthService
 */
export const useOauthServices = (): Record<OauthProvider, OauthService> => {
  const api = useContext(ApiContext).api;
  return {
    zoho: new OauthService(OauthProvider.ZohoCRM, api),
    vcrm: new OauthService(OauthProvider.VCRM, api),
    intuit: new OauthService(OauthProvider.Intuit, api),
    google: new OauthService(OauthProvider.Google, api),
    microsoft: new OauthService(OauthProvider.Microsoft, api),
  };
};
