import {
  BulkDeleteButton,
  List,
  Resource,
  TextField,
  NumberField,
  Edit,
  SimpleForm,
  DateTimeInput,
  TextInput,
  NumberInput,
  Create,
  ChipField,
  SelectInput,
  DateField,
  TopToolbar,
  SelectColumnsButton,
  DatagridConfigurable,
  CreateButton,
  Loading,
  FilterList,
  FilterListItem,
  FilterLiveSearch,
  useListController,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-permissions";
import {
  ConversionFilterListResource,
  ConversionResource,
  ConversionSource,
  toFilterParams,
} from "@arrowsup/platform-dtos";
import { omit } from "lodash";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import SavedSearchIcon from "@mui/icons-material/SavedSearch";
import ClearIcon from "@mui/icons-material/Clear";
import ConversionIcon from "./ConversionIcon";
import { useConversionFilters } from "../../logic/conversions/use-conversion-filter";
import {
  formatTimestamp,
  parseTimestamp,
} from "../../logic/api/timestamp-codec";

export const Conversion = (perms: CrudPermissions) => {
  return (
    <Resource
      name="conversion"
      key={"conversion"}
      options={{
        label: "Conversions",
      }}
      list={ConversionList(perms)}
      edit={perms.update ? ConversionEdit : undefined}
      create={perms.create ? ConversionCreate : undefined}
      icon={ConversionIcon}
    />
  );
};

const EventTimeInput: React.FC = () => {
  return (
    <>
      <DateTimeInput
        source="eventTimeMs"
        format={formatTimestamp}
        parse={parseTimestamp}
        label="Time"
        fullWidth
        required
      />
    </>
  );
};

const SourceInput = () => {
  return (
    <>
      <SelectInput
        source="source"
        label="Source"
        fullWidth
        choices={Object.keys(ConversionSource.schema).map((key) => ({
          id: key,
          name: key,
        }))}
        required
      />
    </>
  );
};

const ConversionListActions = () => {
  return (
    <TopToolbar>
      <SelectColumnsButton />
      <CreateButton />
    </TopToolbar>
  );
};

const ConversionFilters = () => {
  // Load saved filters.
  const { data, isPending, error } = useConversionFilters();
  const { setFilters } = useListController({ resource: "conversion" });

  if (isPending) {
    return <Loading />;
  }

  if (error) {
    throw new Error(`Error loading conversion filters: ${error.message}`);
  }

  const savedFilters = data.data as ConversionFilterListResource[];

  return (
    <Card
      sx={{
        display: { xs: "none", md: "block" },
        order: -1,
        flex: "0 0 15em",
        mr: 2,
        mt: 6,
        alignSelf: "flex-start",
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <FilterLiveSearch hiddenLabel />
        <FilterList label="Saved Filters" icon={<SavedSearchIcon />}>
          {savedFilters.length === 0 ? (
            <Typography sx={{ ml: "2rem" }} variant="caption">
              No Saved Filters
            </Typography>
          ) : null}
          {savedFilters.map((filter) => {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
            const filterParam = toFilterParams(filter);
            return (
              <FilterListItem
                key={filter.name}
                label={filter.name}
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                value={filterParam}
              />
            );
          })}
          <Box sx={{ p: 1 }} />
          <Button
            onClick={() => setFilters({})}
            sx={{ mx: "auto", width: "100%" }}
            size="small"
            color="secondary"
            startIcon={<ClearIcon />}
          >
            Clear Filters
          </Button>
        </FilterList>
      </CardContent>
    </Card>
  );
};

const ConversionList = (perms: CrudPermissions) => {
  // Display list of conversions.
  return (
    <div>
      <List
        exporter={false}
        actions={<ConversionListActions />}
        aside={<ConversionFilters />}
      >
        <DatagridConfigurable
          rowClick={perms.read ? "show" : false}
          bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
          omit={
            [
              "eventKey",
              "firstName",
              "lastName",
              "destinationPhone",
              "gender",
              "dob",
              "streetAddress",
              "city",
              "state",
              "zip",
              "country",
              "userAgent",
              "clientAddress",
              "url",
            ] satisfies (keyof ConversionResource)[]
          }
        >
          <DateField
            source="eventTimeMs"
            showTime
            transform={(value: number) => new Date(value)}
            label="Time"
          />
          <ChipField source="source" label="Source" />
          <TextField source="eventType" label="Type" />
          <TextField source="eventKey" label="Key" />
          <TextField source="user" label="User ID" />
          <TextField source="firstName" label="First Name" />
          <TextField source="lastName" label="Last Name" />
          <TextField source="email" label="Email" />
          <TextField source="phone" label="Phone" />
          <TextField source="destinationPhone" label="Dst. Phone" />
          <TextField source="gender" label="Gender" />
          <TextField source="dob" label="DOB" />
          <TextField source="streetAddress" label="Street Addr." />
          <TextField source="city" label="City" />
          <TextField source="state" label="State" />
          <TextField source="zip" label="Zip" />
          <TextField source="country" label="Country" />
          <TextField source="userAgent" label="User Agent (Web)" />
          <TextField source="clientAddress" label="Client Address (Web)" />
          <TextField source="url" label="URL (Web)" />
          <NumberField
            source="value"
            options={{ style: "currency", currency: "USD" }}
          />
        </DatagridConfigurable>
      </List>
    </div>
  );
};

const ConversionEdit = () => {
  return (
    <div>
      <Edit
        redirect="list"
        transform={
          (d: ConversionResource) =>
            omit(d, [
              "createdAt",
              "updatedAt",
              "org",
              "phoneNormalized",
              "friendlyName",
              "attribute",
            ]) // Don't send these fields to the server.
        }
      >
        <SimpleForm>
          <EventTimeInput />
          <SourceInput />
          <TextInput source="eventType" label="Type" fullWidth />
          <TextInput source="eventKey" label="Key" fullWidth />
          <TextInput source="user" label="User ID" fullWidth />
          <TextInput source="firstName" label="First Name" fullWidth />
          <TextInput source="lastName" label="Last Name" fullWidth />
          <TextInput source="email" label="Email" fullWidth />
          <TextInput source="phone" label="Phone" fullWidth />
          <TextInput source="destinationPhone" label="Dst. Phone" fullWidth />
          <TextInput source="gender" label="Gender" fullWidth />
          <TextInput source="dob" label="DOB" fullWidth />
          <TextInput source="streetAddress" label="Street Addr." fullWidth />
          <TextInput source="city" label="City" fullWidth />
          <TextInput source="state" label="State" fullWidth />
          <TextInput source="zip" label="Zip" fullWidth />
          <TextInput source="country" label="Country" fullWidth />
          <TextInput source="userAgent" label="User Agent (Web)" fullWidth />
          <TextInput
            source="clientAddress"
            label="Client Address (Web)"
            fullWidth
          />
          <TextInput source="url" label="URL (Web)" fullWidth />
          <NumberInput source="value" fullWidth />
        </SimpleForm>
      </Edit>
    </div>
  );
};

const ConversionCreate = () => {
  return (
    <div>
      <Create
        redirect="list"
        transform={(c: ConversionResource) => ({ ...c, attribute: true })}
      >
        <SimpleForm>
          <EventTimeInput />
          <SourceInput />
          <TextInput source="eventType" label="Type" fullWidth />
          <TextInput source="eventKey" label="Key" fullWidth />
          <TextInput source="user" label="User ID" fullWidth />
          <TextInput source="firstName" label="First Name" fullWidth />
          <TextInput source="lastName" label="Last Name" fullWidth />
          <TextInput source="email" label="Email" fullWidth />
          <TextInput source="phone" label="Phone" fullWidth />
          <TextInput source="destinationPhone" label="Dst. Phone" fullWidth />
          <TextInput source="gender" label="Gender" fullWidth />
          <TextInput source="dob" label="DOB" fullWidth />
          <TextInput source="streetAddress" label="Street Addr." fullWidth />
          <TextInput source="city" label="City" fullWidth />
          <TextInput source="state" label="State" fullWidth />
          <TextInput source="zip" label="Zip" fullWidth />
          <TextInput source="country" label="Country" fullWidth />
          <TextInput source="userAgent" label="User Agent (Web)" fullWidth />
          <TextInput
            source="clientAddress"
            label="Client Address (Web)"
            fullWidth
          />
          <TextInput source="url" label="URL (Web)" fullWidth />
          <NumberInput source="value" fullWidth />
        </SimpleForm>
      </Create>
    </div>
  );
};
