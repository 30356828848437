import { OauthProvider } from "@arrowsup/platform-dtos";
import { IntuitOauthConfig } from "./oauth/intuit/IntuitOauthConfig";
import { VcrmOauthConfig } from "./oauth/zoho/VcrmOauthConfig";
import { ZohoOauthConfig } from "./oauth/zoho/ZohoOauthConfig";
import { OauthService } from "../../logic/integrations/oauth/oauth.service";
import { AppConfigService } from "../../logic/app-config/use-app-config.service";
import { GoogleOauthConfig } from "./oauth/google/google-oauth-config";
import { suspend } from "suspend-react";
import { Typography } from "@mui/material";
import { MicrosoftOauthConfig } from "./oauth/microsoft/microsoft-oauth-config";

interface Props {
  oauthServices: Record<OauthProvider, OauthService>;
  acs: AppConfigService;
}

/**
 * This component just lays out the configured OAuth components.
 * All of the actual OAuth logic is done in the components themselves.
 */
export const IntegrationConfigsLayout = ({ oauthServices, acs }: Props) => {
  const config = suspend(acs.config, [acs]);

  // get the app config which contains which oauth applications are enabled for the user's org
  if (
    !config.IntegrationConfig ||
    config.IntegrationConfig.enabledOauthIntegrations.length === 0
  ) {
    return (
      <Typography pt={1} align="center">
        No Integrations Enabled
      </Typography>
    );
  }

  // a record to lookup the OauthConfig components by OauthProvider
  const oauthProviderToConfigUI: Record<OauthProvider, JSX.Element> = {
    [OauthProvider.ZohoCRM]: (
      <ZohoOauthConfig zohoOauthService={oauthServices.zoho} key={"zoho"} />
    ),
    [OauthProvider.VCRM]: (
      <VcrmOauthConfig vcrmOauthService={oauthServices.vcrm} key={"vcrm"} />
    ),
    [OauthProvider.Intuit]: (
      <IntuitOauthConfig
        intuitOauthService={oauthServices.intuit}
        key={"intuit"}
      />
    ),
    [OauthProvider.Google]: (
      <GoogleOauthConfig oauthService={oauthServices.google} key={"google"} />
    ),
    [OauthProvider.Microsoft]: (
      <MicrosoftOauthConfig
        oauthService={oauthServices.microsoft}
        key={"microsoft"}
      />
    ),
  };

  // return a list of the enabled Oauth components
  return (
    <div>
      {config.IntegrationConfig.enabledOauthIntegrations.map(
        (p) => oauthProviderToConfigUI[p]
      )}
    </div>
  );
};
