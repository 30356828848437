export const formatTimestamp = (value: number) => {
  if (!value) {
    return "";
  }

  if (!Number.isInteger(value)) {
    // Did we get the same string back?  (e.g. "2025-03-18T19:27")
    return value;
  }

  const ret = new Date(value).toISOString().slice(0, -8);
  return ret;
};

export const parseTimestamp = (d: string) => {
  return Date.parse(d).valueOf();
};
