import { OauthService } from "../../../../logic/integrations/oauth/oauth.service";
import { OauthConfig } from "../common/OauthConfig";

interface Props {
  oauthService: OauthService;
}

export const MicrosoftOauthConfig = ({ oauthService }: Props) => {
  return (
    <OauthConfig
      name="Microsoft"
      description="Connect your Microsoft (Bing Ads) account to the rest of the platform."
      oauthService={oauthService}
    />
  );
};
