import {
  BulkDeleteButton,
  Create,
  Datagrid,
  Edit,
  List,
  Loading,
  Resource,
  ShowBase,
  SimpleForm,
  TextField,
  TextInput,
  useShowContext,
} from "react-admin";
import { CrudPermissions } from "../auth/crud-permissions";
import { Box, Typography } from "@mui/material";
import { DashboardResource as DashDTO } from "@arrowsup/platform-dtos";
import { useWindowHeight } from "@react-hook/window-size";
import DashboardIcon from "@mui/icons-material/Dashboard";
import { useContext } from "react";
import { AccessTokenContext } from "../../logic/auth/access-token-context";

export const DashboardResource = (perms: CrudPermissions) => {
  return (
    <Resource
      name="dashboard"
      key="dashboard"
      options={{
        label: "Dashboards",
      }}
      list={DashboardList(perms)}
      edit={perms.update ? DashboardEdit : undefined}
      create={perms.create ? DashboardCreate : undefined}
      icon={DashboardIcon}
      show={DashboardShow}
    />
  );
};

const DashboardList = (perms: CrudPermissions) => (
  <div>
    <List exporter={false} sort={{ field: "title", order: "ASC" }} perPage={25}>
      <Datagrid
        rowClick={perms.read ? "show" : false}
        bulkActionButtons={perms.delete ? <BulkDeleteButton /> : false}
      >
        <TextField source="title" />
      </Datagrid>
    </List>
  </div>
);

const DashboardEdit = () => (
  <Edit redirect="list">
    <SimpleForm>
      <TextField source="title" />
      <TextField source="path" />
    </SimpleForm>
  </Edit>
);

const DashboardCreate = () => (
  <Create redirect="list">
    <SimpleForm>
      <TextInput
        source="title"
        helperText="Title of this dashboard."
        fullWidth
      />
      <TextInput
        source="path"
        helperText="Path of this dashboard. I.e. '/{orgId}/{dashboard-path}/index.html'."
        fullWidth
      />
    </SimpleForm>
  </Create>
);

const DashboardEmbed = () => {
  const showContext = useShowContext();
  const windowHeight = useWindowHeight();
  const accessToken = useContext(AccessTokenContext).accessToken;

  if (!showContext.record) {
    return <Loading />;
  }

  const record = showContext.record as DashDTO;

  const height = windowHeight - 100; // Approx padding for nav bar.
  const baseUrl = process.env.REACT_APP_PLATFORM_BASE_URL;
  if (!baseUrl) {
    throw new Error("REACT_APP_PLATFORM_BASE_URL not set");
  }
  const iframeSrc = `${baseUrl}/dashboards${record.path}?au_token=${accessToken}`;

  return (
    <Box
      display="flex"
      flexDirection={"column"}
      justifyContent="center"
      alignItems="center"
      paddingTop="1rem"
    >
      <Typography variant="h6">{record.title}</Typography>
      <iframe
        title={record.title}
        id="dashboard-iframe"
        src={iframeSrc}
        height={height.toString() + "px"}
        width="100%"
        frameBorder={0}
      ></iframe>
    </Box>
  );
};

const DashboardShow = () => {
  return (
    <ShowBase
      resource="dashboard"
      queryOptions={{
        enabled:
          false /* Prevent unnecessary refreshes that erase dashboard state. */,
      }}
    >
      <DashboardEmbed />
    </ShowBase>
  );
};
